<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('CONFIRMATION.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('CONFIRMATION.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
      <vs-card >
            <div id="doc_queue_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="new_confirmations"
                :noDataText="$t('CONFIRMATION.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('CONFIRMATION.NEW_TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">                             
                  <vs-th sort-key="titel_to_be_confirmed">
                    {{$t('CONFIRMATION.NEW_TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="type_to_be_confirmed">
                    {{$t('CONFIRMATION.NEW_TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="state">
                    {{$t('CONFIRMATION.NEW_TABLE.COL3')}}
                  </vs-th>                                                                                                         
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >  
                    <vs-td :data="tr.titel_to_be_confirmed">
                      {{tr.titel_to_be_confirmed}}
                    </vs-td>                  
                    <vs-td :data="tr.type_to_be_confirmed">
                      {{GetDokumentTypeText(tr.type_to_be_confirmed)}}
                    </vs-td>      
                    <vs-td :data="tr.state">
                       <vs-chip :color="GetCommonStateColor(tr.state)">{{GetCommonStateText(tr.state)}}</vs-chip>
                    </vs-td>                                                                                                 
                    <vs-td>
                      <vs-button v-show="IsButtonVisible(tr)" @click="OpenConfirm(tr)" class="ml-2" size="small" color="success" type="filled" icon="check"></vs-button>  
                      <vs-button v-show="IsButtonVisible(tr)" @click="OpenReject(tr)" class="ml-2" size="small" color="danger" type="filled" icon="clear"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
              <vs-prompt
              @cancel="CanceldDlgReject"
              @accept="AcceptedDlgReject"
              @close="CanceldDlgReject"
              :active.sync="dlgRejectActive"
              :title="$t('COM.SURE.TITEL')"
              :accept-text="$t('COM.REJECT')"
              :cancel-text="$t('COM.CANCEL')"
              id="promptReject"
              :is-valid="dlgRejectNote.length > 0">
              <div>
                <div>
                  <p>{{$t('CONFIRMATION.REJECT.TEXT')}}</p>
                  <div class="default-input d-flex align-items-center">
                    <vs-input ref="bez" :label="$t('CONFIRMATION.REJECT.NOTE')"  class="float-left mr-2 inputx" :placeholder="$t('CONFIRMATION.PLACEHOLDER.NOTE')"  v-model="dlgRejectNote" :danger="dlgRejectNote.length <= 0" val-icon-danger="clear"/>
                  </div>
                </div>
              </div>
            </vs-prompt>
            </div>           
        </vs-card>
        <vs-card >
            <div id="doc_queue_table" class="vs-con-loading__container" >
              <vs-table
                :data="old_confirmations"
                :noDataText="$t('CONFIRMATION.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('CONFIRMATION.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">  
                  <vs-th></vs-th>                             
                  <vs-th sort-key="titel_to_be_confirmed">
                    {{$t('CONFIRMATION.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="type_to_be_confirmed">
                    {{$t('CONFIRMATION.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="username_confirmed_by">
                    {{$t('CONFIRMATION.TABLE.COL3')}}
                  </vs-th>  
                  <vs-th sort-key="confirmed_at">
                    {{$t('CONFIRMATION.TABLE.COL4')}}
                  </vs-th>  
                  <vs-th sort-key="state">
                    {{$t('CONFIRMATION.TABLE.COL5')}}
                  </vs-th>
                  <vs-th sort-key="note">
                    {{$t('CONFIRMATION.TABLE.COL6')}}
                  </vs-th>                                                                                                                                                                                                                                                   
                </template>

                <template slot-scope="{data}">
                  <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >  
                    <vs-td>
                      <vs-icon :icon="GetIcon(tr)" :color="GetIconColor(tr)" size="small"></vs-icon>
                    </vs-td> 
                    <vs-td :data="tr.titel_to_be_confirmed">
                      {{tr.titel_to_be_confirmed}}
                    </vs-td>                  
                    <vs-td :data="tr.type_to_be_confirmed">
                      {{GetDokumentTypeText(tr.type_to_be_confirmed)}}
                    </vs-td>     
                    <vs-td :data="tr.username_confirmed_by">
                      {{tr.username_confirmed_by}}
                    </vs-td> 
                    <vs-td :data="tr.confirmed_at">
                      {{DateToString(tr.confirmed_at,false)}}
                    </vs-td>   
                    <vs-td :data="tr.state">
                      <vs-chip :color="GetCommonStateColor(tr.state)">{{GetCommonStateText(tr.state)}}</vs-chip>
                    </vs-td>
                    <vs-td :data="tr.note">
                      {{tr.note}}
                    </vs-td>                                                                                                                                                                                                                                                                                            
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>        
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import enumHelper from '../helper/enumHelper';
import codeHelper from '../helper/staticCodeHelper';
import {commonStates} from '../helper/enumHelper';

export default {
  name: "Confirmations",
  components: {
  },
    data: function (){
      return {
      new_confirmations:[],
      old_confirmations:[],
      dlgRejectNote:"",
      dlgRejectActive:false,
      actData:null
      };
  },
  mounted () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.confirmation.confirmations.data != null)
      {
        data = this.$store.state.confirmation.confirmations.data
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.confirmation.confirmations.status) != 'undefined')
        loading = this.$store.state.confirmation.confirmations.status.loading;
      return loading;
    }
  },
  watch: {
      getTableData(value) {
        this.new_confirmations = [];
        this.old_confirmations = [];

          value.forEach(element => {
            if(element.state == commonStates.CONFIRMED || element.state == commonStates.REJECTED)
            {
              this.old_confirmations.push(element);
            }
            else
            {
              this.new_confirmations.push(element);
            }
          });
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#doc_queue_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#doc_queue_table'});
        }
      },
    },
  methods: {    
      ...helperMethods,
      ...enumHelper,
      ...codeHelper,
      LoadData: function ()
      {
          this.$store.dispatch('confirmation/getConfirmations'); 
          this.ResetDlgData();
      },
      GetIcon(confirmation){
      var icon = "priority_high";

      if(this.IsNotNull(confirmation))
      {

        if(confirmation.state == commonStates.CONFIRMED)
        {
          icon = "check";
        }

        if(confirmation.state == commonStates.REJECTED)
        {
          icon = "clear";
        }
      }
      return icon;
    },
    GetIconColor(confirmation){
      var color = "primary";

      if(this.IsNotNull(confirmation))
      {

        if(confirmation.state == commonStates.CONFIRMED)
        {
          color = "success";
        }

        if(confirmation.state == commonStates.REJECTED)
        {
          color = "danger";
        }
      }
      return color;
    }, 
    IsButtonVisible(confirmation){
      var visible = false;

      if(this.IsNotNull(confirmation))
      {
        visible = !(confirmation.state == commonStates.CONFIRMED || confirmation.state == commonStates.REJECTED);
      }
      return visible;
    },     
      OpenConfirm(data) {
        this.$vs.dialog({
          type: "confirm",
          color: "success",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.CONFIRM'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('CONFIRMATION.QUESTION_CONFIRM').replace("*1*",data.titel_to_be_confirmed),
          accept: this.UpdateConfirmation,
          parameters:{"data": data, "confirm":true}
        });
      
      },
      ResetDlgData(){
        this.actData = null;
        this.dlgRejectNote = "";
      },
      OpenReject(data){
        this.actData = data;
        this.dlgRejectActive = true;
      },
      CanceldDlgReject(){
        this.ResetDlgData();
      },
      AcceptedDlgReject() {
        
        this.actData.note = this.dlgRejectNote;
        var parameters = {"data": this.actData, "confirm":false};
        this.UpdateConfirmation(parameters);
        this.ResetDlgData();
      },
      UpdateConfirmation: function(parameters)
      {
        var text_success = "";
        var text_fail = "";
        if(parameters.confirm == true)
        {
          parameters.data.state = commonStates.CONFIRMED;
          text_success = this.$t('CONFIRMATION.SUCCESS.CONFIRM').replace("*1*",parameters.data.titel_to_be_confirmed);
          text_fail = this.$t('CONFIRMATION.WARNING.CONFIRM').replace("*1*",parameters.data.titel_to_be_confirmed);
        }
        else
        {
          parameters.data.state = commonStates.REJECTED;
          text_success = this.$t('CONFIRMATION.SUCCESS.REJECT').replace("*1*",parameters.data.titel_to_be_confirmed);
          text_fail = this.$t('CONFIRMATION.WARNING.REJECT').replace("*1*",parameters.data.titel_to_be_confirmed);
        }

        this.$store.dispatch('confirmation/updateConfirmation', parameters.data)
        .then(response => {
          if(response.success == true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':text_success});  
          }
          else
          {
            text_fail = text_fail.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text_fail}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('CONFIRMATION.ERROR.CONFIRM').replace("*1*",error)});  
        });
      }
  }
};

</script>